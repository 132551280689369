.Progress {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
}
.Progress svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 50%;
    height: auto;
}

.Progress div {
    /*color: #40F3FF;*/
    position: absolute;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 15px;
    padding-top: 10px;
    bottom: 10px
}
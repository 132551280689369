.Home {
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.Home-GL {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
}

.Home .Link {
    font-size: 50px;
    color: white;
}

.Home canvas {
    width: 100% !important;
    height: 100% !important;
    opacity: 0.95;
}

.Home > span {
    z-index: 1;
    position: relative;
}

.Music {
    position: absolute;
    top: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.0);
    right: 0;
    width: 58px;
    height: 58px;
    padding: 15px;
    box-sizing: border-box;
}

.Music svg.PlayButton {
    width: 100%;
    height: 100%;
    z-index: 2;
}
.Music svg.TipsArrow {
    z-index: 1;
    position: absolute;
    width: 22px;
    height: auto;
    left: 50%;
    top: 50px;
    transform: translate(-50%, 0);
}

.Music.Played svg.PlayButton {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    animation-fill-mode: forwards;
}
.Music.Played svg.TipsArrow {
   display: none;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



@-moz-keyframes arrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(10px,0);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes arrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(10px,0);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@keyframes arrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(10px,0);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes arrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes arrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@keyframes arrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes arrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(10px,0);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes arrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(10px,0);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@keyframes arrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(10px,0);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}
svg .arrow1 {
    -webkit-animation: arrow1 2s linear infinite;
    -moz-animation: arrow1 2s linear infinite;
    animation: arrow1 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .arrow2 {
    -webkit-animation: arrow2 2s linear infinite;
    -moz-animation: arrow2 2s linear infinite;
    animation: arrow2 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .arrow3 {
    -webkit-animation: arrow3 2s linear infinite;
    -moz-animation: arrow3 2s linear infinite;
    animation: arrow3 2s linear infinite;
    animation-fill-mode: forwards;
}




@-moz-keyframes upArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes upArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@keyframes upArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes upArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes upArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@keyframes upArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes upArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes upArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@keyframes upArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

svg .upArrow1 {
    -webkit-animation: upArrow1 2s linear infinite;
    -moz-animation: upArrow1 2s linear infinite;
    animation: upArrow1 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .upArrow2 {
    -webkit-animation: upArrow2 2s linear infinite;
    -moz-animation: upArrow2 2s linear infinite;
    animation: upArrow2 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .upArrow3 {
    -webkit-animation: upArrow3 2s linear infinite;
    -moz-animation: upArrow3 2s linear infinite;
    animation: upArrow3 2s linear infinite;
    animation-fill-mode: forwards;
}

.Next {
    -webkit-animation: upArrow3 2s linear infinite;
    -moz-animation: upArrow3 2s linear infinite;
    animation: upArrow3 2s linear infinite;
    animation-fill-mode: forwards;
}

@-moz-keyframes nextArrow {
    0% {
        transform: translate(0px,0);
    }
    50% {
        transform: translate(0,10px);
    }
    100% {
        transform: translate(0px,0);
    }
}

@-webkit-keyframes nextArrow {
    0% {
        transform: translate(0px,0);
    }
    50% {
        transform: translate(0,10px);
    }
    100% {
        transform: translate(0px,0);
    }
}

@keyframes nextArrow {
    0% {
        transform: translate(0px,0);
    }
    50% {
        transform: translate(0,10px);
    }
    100% {
        transform: translate(0px,0);
    }
}







@-moz-keyframes rightUpArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes rightUpArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@keyframes rightUpArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes rightUpArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes rightUpArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@keyframes rightUpArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes rightUpArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes rightUpArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@keyframes rightUpArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(-10px,-10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}
svg .rightUpArrow1 {
    -webkit-animation: rightUpArrow1 2s linear infinite;
    -moz-animation: rightUpArrow1 2s linear infinite;
    animation: rightUpArrow1 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .rightUpArrow2 {
    -webkit-animation: rightUpArrow2 2s linear infinite;
    -moz-animation: rightUpArrow2 2s linear infinite;
    animation: rightUpArrow2 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .rightUpArrow3 {
    -webkit-animation: rightUpArrow3 2s linear infinite;
    -moz-animation: rightUpArrow3 2s linear infinite;
    animation: rightUpArrow3 2s linear infinite;
    animation-fill-mode: forwards;
}

@-moz-keyframes leftDownArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes leftDownArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@keyframes leftDownArrow1 {
    0% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #80F7FF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes leftDownArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes leftDownArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@keyframes leftDownArrow2 {
    0% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
    50% {
        fill: #BFFAFF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #40F3FF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes leftDownArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes leftDownArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}

@keyframes leftDownArrow3 {
    0% {
        fill: #BFFAFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #40F3FF;
        transform: translate(10px,10px);
    }
    100% {
        fill: #80F7FF;
        transform: translate(0px,0);
    }
}
svg .leftDownArrow1 {
    -webkit-animation: leftDownArrow1 2s linear infinite;
    -moz-animation: leftDownArrow1 2s linear infinite;
    animation: leftDownArrow1 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .leftDownArrow2 {
    -webkit-animation: leftDownArrow2 2s linear infinite;
    -moz-animation: leftDownArrow2 2s linear infinite;
    animation: leftDownArrow2 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .leftDownArrow3 {
    -webkit-animation: leftDownArrow3 2s linear infinite;
    -moz-animation: leftDownArrow3 2s linear infinite;
    animation: leftDownArrow3 2s linear infinite;
    animation-fill-mode: forwards;
}

@-moz-keyframes whiteArrow1 {
    0% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes whiteArrow1 {
    0% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@keyframes whiteArrow1 {
    0% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes whiteArrow2 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes whiteArrow2 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
}

@keyframes whiteArrow2 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(10px,0);
    }
    100% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
}

@-moz-keyframes whiteArrow3 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EEEEEE;
        transform: translate(10px,0);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes whiteArrow3 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EEEEEE;
        transform: translate(10px,0);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@keyframes whiteArrow3 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EEEEEE;
        transform: translate(10px,0);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}
svg .whiteArrow1 {
    -webkit-animation: whiteArrow1 2s linear infinite;
    -moz-animation: whiteArrow1 2s linear infinite;
    animation: whiteArrow1 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .whiteArrow2 {
    -webkit-animation: whiteArrow2 2s linear infinite;
    -moz-animation: whiteArrow2 2s linear infinite;
    animation: whiteArrow2 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .whiteArrow3 {
    -webkit-animation: whiteArrow3 2s linear infinite;
    -moz-animation: whiteArrow3 2s linear infinite;
    animation: whiteArrow3 2s linear infinite;
    animation-fill-mode: forwards;
}




@-moz-keyframes upWhiteArrow1 {
    0% {
        fill: #EFEFEF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EEEEEE;
        transform: translate(0,-10px);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes upWhiteArrow1 {
    0% {
        fill: #EFEFEF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EEEEEE;
        transform: translate(0,-10px);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@keyframes upWhiteArrow1 {
    0% {
        fill: #EFEFEF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EEEEEE;
        transform: translate(0,-10px);
    }
    100% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes upWhiteArrow2 {
    0% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #EFEFEF;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes upWhiteArrow2 {
    0% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #EFEFEF;
        transform: translate(0px,0);
    }
}

@keyframes upWhiteArrow2 {
    0% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
    50% {
        fill: #FFFFFF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #EFEFEF;
        transform: translate(0px,0);
    }
}

@-moz-keyframes upWhiteArrow3 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EFEFEF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
}

@-webkit-keyframes upWhiteArrow3 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EFEFEF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
}

@keyframes upWhiteArrow3 {
    0% {
        fill: #FFFFFF;
        transform: translate(0px,0);
    }
    50% {
        fill: #EFEFEF;
        transform: translate(0,-10px);
    }
    100% {
        fill: #EEEEEE;
        transform: translate(0px,0);
    }
}

svg .upWhiteArrow1 {
    -webkit-animation: upWhiteArrow1 2s linear infinite;
    -moz-animation: upWhiteArrow1 2s linear infinite;
    animation: upWhiteArrow1 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .upWhiteArrow2 {
    -webkit-animation: upWhiteArrow2 2s linear infinite;
    -moz-animation: upWhiteArrow2 2s linear infinite;
    animation: upWhiteArrow2 2s linear infinite;
    animation-fill-mode: forwards;
}
svg .upWhiteArrow3 {
    -webkit-animation: upWhiteArrow3 2s linear infinite;
    -moz-animation: upWhiteArrow3 2s linear infinite;
    animation: upWhiteArrow3 2s linear infinite;
    animation-fill-mode: forwards;
}

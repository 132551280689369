.Scenes {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top:0;
    left: 0;
    z-index: 1;
    transition: opacity 3s ease-in-out;
}

.Scenes .Background {
    width: calc(100% + 64px);
    z-index: 1;
    position: absolute;
    top: 0;
}

.Scenes .Background img {
    width: 100%;
    height: auto;
    display: block;
}

.Scenes .Foreground {
    color: white;
    /*padding: 10px;*/
    position: relative;
    z-index: 2;
    /*top: 0;*/
    /*left: 0;*/
    width: 100%;
    /*height: 400%;*/
}

.SceneWrapper {
    width: calc(100% + 64px);
    transform: translate(-32px, 0);
    z-index: 3;
    position: relative;
    box-sizing: border-box;
    height: 100%;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.Scene {
    position: relative;
}

.Scene > svg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.Scene > img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.Scene > img:first-child {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.SceneStar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    opacity: 0;
    transition: opacity 1s linear;
}

.Next {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 25px);
    z-index: 1000;
    width: 50px;
    height: 45px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    opacity: 1;
    visibility: visible;
    transition: opacity 500ms ease-in-out;
}
.NextHidden.Next {
    opacity: 0;
    visibility: hidden;
}

.Next svg {
    width: 100%;
    height: auto;
}

.SceneStar .Tips {
    opacity: 0;
    transition: opacity 3s linear;
}

.SceneStar.SceneArrowShow .Tips {
    opacity: 1;
}

.SceneStarShow {
    opacity: 1;
}

.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*z-index: 1000000;*/
    height: 100%;
    background-color: rgba(0, 0, 0, .45);
    filter: alpha(opacity=50);
}

.ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}

.ant-modal-content {
    position: relative;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
    pointer-events: auto;
}

.ant-modal-content {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.ShareModal {
    width: 100% !important;
    height: 100% !important;
}


.Scene img {
    width: 100%;
    height: 100%;
}

.Scenes .Scene img:first-child {
    transform: translate(-32px, 0);
    animation-name: shifting-left;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
.Scenes .Scene .Scene-BG {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;
    transform: translate(-32px, 0);
    animation-name: shifting-left;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

/*.Scenes .Background .BackgroundWrapper2 {*/
/*    transform: translate(-64px, 0);*/
/*    animation-name: shifting-left;*/
/*    !*animation-iteration-count: infinite;*!*/
/*    animation-duration: 30s;*/
/*    animation-delay: 10s;*/
/*    animation-timing-function: linear;*/
/*    animation-fill-mode: forwards;*/
/*}*/


@keyframes shifting-left {
    0% {
        transform: translate(-32px, 0);
    }
    5% {
        transform: translate(-32px, 0);
    }
    45% {
        transform: translate(32px, 0);
    }
    55% {
        transform: translate(32px, 0);
    }
    95% {
        transform: translate(-32px, 0);
    }
    95% {
        transform: translate(-32px, 0);
    }
}

@keyframes shifting-right {
    0% {
        transform: translate(0px, 0);
    }
    100% {
        transform: translate(-64px, 0);
    }
}

.ShareImage {
    /*height: calc(100% - 60px);*/
    /*width: 100%;*/
    position: relative;
    z-index: 2;
}

.ShareImage img {
    max-width: calc(100% - 100px);
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ShareImage img:first-child {
    z-index: 1;
    box-shadow: 5px 5px 4px 1px rgba(0, 0, 0, 0.3);
}

.ShareImage img:last-child {
    /*box-shadow: 5px 5px 4px 1px rgba(0, 0, 0, 0.3);*/
    z-index: 2;
    opacity: 0;
}

.ant-modal-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.TextContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.TextContainer svg {
    width: 100%;
    height: auto;
}

.TextContainer .TextWrapper {
    width: calc(100% - 40px);
    height: auto;
    position: relative;
}

.TextContainer-texts101 .TextWrapper {
    position: absolute;
    top: 20px;
    left: 20px;
}
.TextContainer-texts102 .TextWrapper {
    position: absolute;
    bottom: 40px;
    left: 20px;
}

.TextContainer-texts201 .TextWrapper {
    position: absolute;
    top: 20px;
    left: 20px;
}
.TextContainer-texts202 .TextWrapper {
    position: absolute;
    bottom: 40px;
    left: 20px;
}

.TextContainer-texts301 .TextWrapper {
    position: absolute;
    top: 20px;
    left: 20px;
}
.TextContainer-texts302 .TextWrapper {
    position: absolute;
    bottom: 40px;
    left: 20px;
}

.TextContainer-texts401 .TextWrapper {
    position: absolute;
    top: 20px;
    left: 20px;
}
.TextContainer-texts402 .TextWrapper {
    position: absolute;
    bottom: 40px;
    left: 20px;
}

.ShareText {
    height: 99px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    padding: 20px 20px;
    box-sizing: border-box;
    font-size: 13px;
    color: white;
    line-height: 23px;
    position: relative;
    z-index: 2;
}

.ShareClose {
    position: absolute;
    right: 20px;
    top: 20px;
}

.ant-modal-mask {
    visibility: visible;
    opacity: 0;
}

.ant-modal-mask-hidden {
    visibility: hidden;
    opacity: 0;
}


svg * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    outline: none;
    pointer-events: bounding-box;
}


svg *:focus {
    outline: none;
}

* {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.HomeCover {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: color-dodge;
    z-index: 4;
}

.HomeCover svg.Logo {
    position: absolute;
    bottom: 10px;
    left: 30px;
    width: 50%;
}

.HomeCover svg.Texts {
    position: absolute;
    top: 20px;
    left: 30px;
    width: calc(100% - 60px);
}
